/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubjectSelectionPathways } from "@/ts/interfaces/Checkout/SubjectInterface";
import { UserInterface } from "@/ts/interfaces/Parent/UserInterface";
import { usePage } from "@inertiajs/react";

const useParentsProps = () => {
    const props = usePage().props as unknown as {
        subjectChangeFeeItemPriceId?: string;
        subjectChangeFeeItemPrice?: number;
        changedSubjects?: number;
        parentUser?: UserInterface;
        student?: UserInterface;
        students: UserInterface[];
        pathway: string;
        pathwayByLicense: SubjectSelectionPathways;
        cancellations: {
            reasons: {
                id: number;
                value: string;
                item_type: "auto_renewal" | "cancellation";
            }[];
            feeText: string;
            coolingOffDurationText: string;
        };
    };

    return props;
};

export default useParentsProps;